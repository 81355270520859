
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import { VueRecaptcha } from 'vue-recaptcha'

export default defineComponent({
	components: {
		VueRecaptcha,
	},
	data() {
		const formRef = ref(null)
		const modelRef = ref({
			email: '',
			pwd: '',
			rePwd: '',
			agree: false,
		})
		function pwdValidate(value: string) {
			if (!value) {
				return new Error('パスワードを入力してください。')
			} else if (value.length < 8) {
				return new Error('パスワードは8文字以上入力する必要があります。')
			} else if (value.length > 99) {
				return new Error('パスワードは99文字以上入力できません。')
			} else if (!/[a-zA-Z]/.test(value)) {
				return new Error('パスワードには英字を含める必要があります。')
			} else if (!/[0-9]/.test(value)) {
				return new Error('パスワードには数字を含める必要があります。')
			} else if (!/^[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':|_~`]{8,99}$/.test(value)) {
				return new Error('パスワードに使用できない文字が含まれています。')
			}
			return true
		}
		function mailValidate(value: string) {
			if (!value) {
				return new Error('メールアドレスを入力してください。')
			} else if (!/^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9.-]+)$/.test(value)) {
				return new Error('正しい形式でメールアドレスを入力してください。')
			}
			return true
		}
		return {
			formRef,
			model: modelRef,
			univName: '',
			loading: false,
			resendDone: false,
			recaptcha: '',
			mode: 'signup',
			err: '',
			pwdValidate,
			mailValidate,
			rules: {
				email: [
					{
						required: true,
						validator(rule: any, value: string) {
							return mailValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				pwd: [
					{
						required: true,
						validator(rule: any, value: string) {
							return pwdValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				rePwd: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (value !== modelRef.value.pwd) return new Error('パスワードが一致しません。')
							return true
						},
						trigger: ['input', 'blur'],
					},
				],
				agree: [
					{
						required: true,
					},
				],
			},
		}
	},
	mounted: function () {
		document.title = '新規登録 - Vets Index'
		localStorage.removeItem('credential')
	},
	methods: {
		post: async function () {
			const param = {
				email: this.model.email,
				password: this.model.pwd,
				role: 'hospital',
				recaptcha: this.recaptcha,
			}
			try {
				this.loading = true
				const refs = this.$refs as any
				refs.recaptcha.reset()
				const em = this.mailValidate(this.model.email)
				if (em !== true) throw em
				const pwd = this.pwdValidate(this.model.pwd)
				if (pwd !== true) throw pwd
				const data = await api.postWithoutToken('/v1/signup', param)
				this.loading = false
				if (data.success) {
					this.mode = 'verify'
				} else {
					if (data.requiredRepost) this.post()
					if (data.message) this.err = data.message
				}
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				console.log(this.err)
				console.error(e)
			}
		},
		resend: async function () {
			const param = {
				username: this.model.email,
				role: 'hospital',
			}
			try {
				this.loading = true
				const data = await api.postWithoutToken('/v1/account/resend_code', param)
				if (data.success) {
					this.resendDone = true
				} else {
					this.loading = false
					if (data.message) this.err = data.message
				}
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				console.log(this.err)
				console.error(e)
			}
		},
		startRecaptcha: function () {
			const refs = this.$refs as any
			refs.recaptcha.execute()
		},
		recaptchaExpired: function () {
			const refs = this.$refs as any
			console.log(refs)
			refs.recaptcha.reset()
		},
		onVerify: function (response: string) {
			this.recaptcha = response
		},
	},
})
